import * as React from 'react'
import classNames from 'classnames'
import * as styles from '../styles/index.module.scss'
import '../styles/booksy.scss'

import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO'
import BooksyButton from '../components/BooksyButton/BooksyButton'

function HomePage() {
  return (
    <Layout>
      <SEO
        title="Strona główna"
        description="Strona główna Hekate - Salon Pielęgnacji Psów"
      />
      <div
        className={classNames(
          'h-screen relative',
          styles.homepage,
          styles.homepage__background
        )}
      >
        <div className="text-gold max-w-lg absolute right-1/2 translate-x-1/2 sm:translate-x-0 sm:right-10 bottom-1/3 min-w-[375px]">
          <p className="font-serif text-2xl font-thin text-center leading-[74px] tracking-widest">
            Ktoś, kto nigdy nie miał psa, przeoczył cudowną część życia.
          </p>
          <p className="font-serif text-lg text-right pr-10 mt-4">
            ~ B. Barker
          </p>
        </div>
        <BooksyButton />
      </div>
    </Layout>
  )
}

export default HomePage
